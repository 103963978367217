/* You can add global styles to this file, and also import other style files */
@import 'primeflex/primeflex.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
   font-family: var(--font-family);
   padding: 0;
   margin: 0;
}

.p-treenode-label {
   width: 100%;
}

.red-checkbox .p-checkbox-box {
   border-color: red !important;
   &.p-highlight {
      background-color: red !important;
   }
}

.custom-bar {
   .p-progressbar {
      border-radius: 0px;
   }

   .p-progressbar-label {
      color: black;
      font-weight: 600;
   }

   .p-progressbar-value {
      padding-left: 25px;
      overflow: visible !important;
   }

   &.even {
      .p-progressbar {
         background-color: white;
      }
   }
}

.p-buttonset .p-button-active {
   border: 1px solid !important;
}

.svg-blue {
   filter: invert(38%) sepia(93%) saturate(4089%) hue-rotate(200deg) brightness(102%) contrast(106%);
}

.svg-gray {
   filter: invert(99%) sepia(55%) saturate(318%) hue-rotate(207deg) brightness(112%) contrast(80%);
}

.p-menuitem-link-active {
   font-weight: bold !important;
}

.p-menuitem-link-active::after {
   text-wrap: nowrap;
   padding: 0 10px;
   content: ' ->';
}
